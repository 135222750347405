import React, { FunctionComponent } from 'react';
import { Container, Info, NoWrap } from './style';

export const Footer: FunctionComponent<{}> = () => {
    return (
        <Container>
            <Info>
                <NoWrap>&copy; 2020</NoWrap>{' '}
                <NoWrap>
                    &laquo;regenesisinc.com&raquo; &mdash; a Regenesis Consulting, LLC hosted website
                </NoWrap>
            </Info>
            <Info>Our website uses no cookies.</Info>
        </Container>
    );
};
